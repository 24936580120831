exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-astro-astrolayout-js": () => import("./../../../src/pages/astro/astrolayout.js" /* webpackChunkName: "component---src-pages-astro-astrolayout-js" */),
  "component---src-pages-astro-equipment-index-js": () => import("./../../../src/pages/astro/equipment/index.js" /* webpackChunkName: "component---src-pages-astro-equipment-index-js" */),
  "component---src-pages-astro-index-js": () => import("./../../../src/pages/astro/index.js" /* webpackChunkName: "component---src-pages-astro-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-performance-tracing-in-chrome-and-edge-js": () => import("./../../../src/pages/blog/performance-tracing-in-chrome-and-edge.js" /* webpackChunkName: "component---src-pages-blog-performance-tracing-in-chrome-and-edge-js" */),
  "component---src-pages-blog-resize-observer-js": () => import("./../../../src/pages/blog/resize-observer.js" /* webpackChunkName: "component---src-pages-blog-resize-observer-js" */),
  "component---src-pages-blog-using-skywatcher-8-x-50-finder-as-guide-scope-js": () => import("./../../../src/pages/blog/using-skywatcher-8x50-finder-as-guide-scope.js" /* webpackChunkName: "component---src-pages-blog-using-skywatcher-8-x-50-finder-as-guide-scope-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

